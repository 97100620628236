{
  "name": "e-postcard",
  "version": "0.0.0",
  "private": true,
  "scripts": {
    "ng": "ng",
    "start": "ng serve",
    "build:prod": "ng build --configuration production",
    "build:staging": "ng build --configuration staging",
    "build:uat": "ng build --configuration uat",
    "build:development": "ng build --configuration development",
    "build:demo": "ng build --configuration demo",
    "watch": "ng build --watch --configuration development",
    "test": "ng test",
    "lint": "ng lint",
    "lint:styles": "stylelint \"**/*.scss\"",
    "lint:report": "eslint --config .eslintrc.json ./src --format html --output-file report/lint.html",
    "sonar": "sonar-scanner"
  },
  "dependencies": {
    "@angular/animations": "^17.1.1",
    "@angular/cdk": "^17.0.3",
    "@angular/common": "^17.1.1",
    "@angular/compiler": "^17.1.1",
    "@angular/core": "^17.1.1",
    "@angular/forms": "^17.1.1",
    "@angular/platform-browser": "^17.1.1",
    "@angular/platform-browser-dynamic": "^17.1.1",
    "@angular/router": "^17.1.1",
    "@angular/service-worker": "^17.1.1",
    "@ng-select/ng-select": "^12.0.7",
    "fabric": "5.3.0-browser",
    "hammerjs": "^2.0.8",
    "ng-qrcode": "^17.0.0",
    "nx": "^19.0.7",
    "rxjs": "~7.8.1",
    "tslib": "^2.6.2",
    "zone.js": "~0.14.5"
  },
  "devDependencies": {
    "@angular-builders/custom-webpack": "^17.0.2",
    "@angular-devkit/build-angular": "^17.1.1",
    "@angular-eslint/builder": "17.3.0",
    "@angular-eslint/eslint-plugin": "17.3.0",
    "@angular-eslint/eslint-plugin-template": "17.3.0",
    "@angular-eslint/schematics": "17.3.0",
    "@angular-eslint/template-parser": "17.3.0",
    "@angular/cli": "~17.1.1",
    "@angular/compiler-cli": "^17.1.1",
    "@commitlint/cli": "^19.3.0",
    "@commitlint/config-conventional": "^19.2.2",
    "@types/compression": "^1.7.5",
    "@types/dotenv-webpack": "^7.0.7",
    "@types/express": "^4.17.21",
    "@types/fabric": "^5.3.7",
    "@types/hammerjs": "^2.0.45",
    "@types/jasmine": "~4.3.6",
    "@types/node": "^20.12.8",
    "@typescript-eslint/eslint-plugin": "7.8.0",
    "@typescript-eslint/parser": "7.8.0",
    "autoprefixer": "^10.4.19",
    "browser-sync": "^3.0.2",
    "dotenv-webpack": "^8.1.0",
    "eslint": "^8.57.0",
    "husky": "^9.0.11",
    "jasmine-core": "~5.1.2",
    "karma": "~6.4.3",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.1",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "lint-staged": "^15.2.2",
    "postcss": "^8.4.38",
    "prettier": "^3.2.5",
    "sonarqube-scanner": "^3.5.0",
    "stylelint": "^16.5.0",
    "stylelint-config-standard-scss": "^13.1.0",
    "tailwindcss": "^3.4.3",
    "typescript": "~5.2.2"
  }
}
