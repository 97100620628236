import { NgClass } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { POSTCARD_FORMAT_TYPE, POSTCARD_LAYOUT } from '@constants/app.enums';
import { environment } from '@environment/environment';
import { PostcardData } from '@models/app.models';
import { DialogService } from '@services/dialog.service';
import { HttpClientService } from '@services/http-client.service';
import { PostcardService } from '@services/postcard.service';
import { QrCodeModule } from 'ng-qrcode';
import { IconsComponent } from '../icons/icons.component';

@Component({
  selector: 'app-preview',
  standalone: true,
  imports: [IconsComponent, NgClass, QrCodeModule],
  templateUrl: './preview.component.html',
})
export class PreviewComponent implements OnInit {

  @Input() data!: {
    layout: POSTCARD_LAYOUT;
    finalImages: PostcardData;
    format: POSTCARD_FORMAT_TYPE;
    postcardId: number;
  };
  frontImageUrl = '';
  backImageUrl = '';
  isDisplayQr = false;
  postcardImage!: File;
  frontImage!: File;
  backImage!: File | null;
  postcardUrl!: string;
  postcardId!: string;
  isLoading = false;
  isFrontCanvasModified = false;

  readonly layoutType = POSTCARD_LAYOUT;
  readonly qrCodeUrl = environment.baseUrl + 'personal-details/';

  constructor(
    private dialogService: DialogService,
    private postcardService: PostcardService,
    private httpService: HttpClientService
  ) { }

  ngOnInit(): void {
    this.loadImage(this.data.finalImages);
    this.uploadPostcardImages();
  }

  uploadPostcardImages() {
    this.isLoading = true;
    const { frontImage, backImage, postcard } = this.loadImages(this.data?.finalImages);

    const form = new FormData();
    form.append('frontPostcard', frontImage);
    form.append('postcard', postcard);
    if (backImage) {
      form.append('backPostcard', backImage);
    }
    this.httpService.post('viewer/uploadPostcard', form).subscribe(res => {
      if (res?.data._id) {
        this.postcardId = res?.data._id;
        this.isLoading = false;
        this.isFrontCanvasModified = false;
      }
    },
      (error) => {
        console.error(error);
        this.isLoading = false;
      });
  }

  popupClose() {
    this.dialogService.close();
  }

  loadImage(finalImages: PostcardData) {
    this.frontImageUrl = finalImages.frontCanvas?.src;
    if (finalImages.backCanvas?.src) {
      this.backImageUrl = finalImages.backCanvas?.src;
    }
  }

  loadImages(finalImages: PostcardData) {
    const frontImage = this.postcardService.dataURLtoFile(finalImages.frontCanvas.src);
    const backImage = finalImages.backCanvas ? this.postcardService.dataURLtoFile(finalImages.backCanvas.src) : null;
    const postcard = this.postcardService.dataURLtoFile(finalImages.postcard.src);
    return { frontImage, backImage, postcard };
  }
}
