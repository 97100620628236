<div class="fixed flex justify-center items-center inset-0 z-50 backdrop-blur-sm">
  <div class="fixed opacity-50 top-0 inset-0 bg-black transition-all" (click)="popupClose()" aria-hidden="true">
  </div>
  <div class="bg-neutral-800 rounded-lg pb-2 p-3 max-w-[calc(100%-32px)] relative top-0 transition-all flex">
    <div class="cursor-pointer absolute top-[-32px] right-0 flex items-center" (click)="popupClose()"
      aria-hidden="true">
      Close
      <app-icons class="ml-2" icons="close" width="20" height="20" fill="fill-white" stroke="stroke-white" />
    </div>
    <div class="flex items-center justify-between gap-x-5">
      <div class="flex flex-wrap justify-center px-8 xl:px-14 gap-4 lg:gap-5"
        [ngClass]="{'flex-col': layoutType.HORIZONTAL === data.layout}">
        <img fetchpriority="high" class="max-lg:max-h-[260px] max-w-[260px]" [src]="frontImageUrl" alt="front-image" />
        @if(backImageUrl) {
        <img fetchpriority="high" class="max-lg:max-h-[260px] max-w-[260px]" [src]="backImageUrl" alt="back-image" />
        }
      </div>
      <div class="bg-white rounded-r-lg px-6 py-5 max-w-[310px]">
        <div class="pb-4 border-b border-neutral-200">
          <img fetchpriority="high" src="assets/images/map-logo.svg" alt="" width="59" height="36">
        </div>
        <strong class="text-lg font-['geometria-700'] block uppercase text-amber-500 my-3">Here is your
          personalised postcard!
        </strong>
        <p class="text-black font-['geometria-500']">Download the postcard you created by scanning
          the QR code below and send it to your loved ones.</p>
        <div class="pt-8 pb-6">
          <div class="w-[120px] h-[120px] flex items-center justify-center relative">
            <span class="absolute top-0 left-0 rounded-tl-lg border-l-[3px] border-t-[3px] border-black h-8 w-8"></span>
            <span
              class="absolute top-0 right-0 rounded-tr-lg border-r-[3px] border-t-[3px] border-black h-8 w-8"></span>
            <span
              class="absolute bottom-0 right-0 rounded-br-lg border-r-[3px] border-b-[3px] border-black h-8 w-8"></span>
            <span
              class="absolute bottom-0 left-0 rounded-bl-lg border-l-[3px] border-b-[3px] border-black h-8 w-8"></span>
            @if(!isLoading && postcardId) {
            <qr-code [value]="qrCodeUrl + postcardId" size="110" errorCorrectionLevel="M"></qr-code>
            } @else {
            <div class="p-3 grid grid-cols-2 gap-1.5 h-full w-full">
              <div class="bg-neutral-300 rounded animate-pulse"></div>
              <div class="bg-neutral-300 rounded animate-pulse"></div>
              <div class="bg-neutral-300 rounded animate-pulse"></div>
              <div class="bg-neutral-300 rounded animate-pulse"></div>
            </div>
            }
          </div>
        </div>
        <p class="text-base font-['geometria-500'] block text-zinc-800 mb-3">Hope we meet again,<br>
          Goodbye!</p>
        <p class="text-base font-['geometria-500'] block text-zinc-800">P.S. Don’t forget to tag
          &commat;mapbengaluru on
          social media platforms!</p>
      </div>
    </div>
  </div>
</div>